var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"campaigns-page admin-page"},[_c('div',{staticClass:"container"},[_c('AdminHeader'),_c('div',{staticClass:"heading"},[_c('v-breadcrumbs',{staticClass:"breadcrumbs",attrs:{"items":[
          {
            text: 'Kampagnen',
            to: '/admin/campaigns',
          } ]},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("chevron_right")])]},proxy:true},{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('v-breadcrumbs-item',{attrs:{"to":item.to,"exact":"","exact-active-class":"active"}},[(item.to == _vm.$route.path)?_c('h2',{staticClass:"breadcrumbItem"},[_vm._v(" "+_vm._s(item.text)+" ")]):_c('p',{staticClass:"breadcrumbItem"},[_vm._v(_vm._s(item.text))])])]}}])})],1),_c('v-btn',{staticClass:"add",attrs:{"color":"primary","elevation":"0","to":"add"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" add ")]),_vm._v(" Kampagne hinzufügen ")],1),_c('v-list',{staticClass:"list",attrs:{"two-line":""}},[(_vm.campaigns && _vm.campaigns.campaigns)?_c('v-list-item-group',[_vm._l((_vm.campaigns.campaigns),function(item,index){return [_c('v-list-item',{key:item.id,staticClass:"item",attrs:{"to":'/admin/campaigns/' + item.id},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"itemTitle",domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
                    item.coupons +
                      ' Gutschein' +
                      (item.coupons != 1 ? 'e' : '')
                  )}})],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("chevron_right")])],1)]},proxy:true}],null,true)}),(index < _vm.campaigns.length - 1)?_c('v-divider',{key:index,staticClass:"mx-3"}):_vm._e()]})],2):_vm._e()],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_c('v-card',[(_vm.error == 'notFound')?[_c('v-card-title',{staticStyle:{"word-break":"break-word"}},[_vm._v(" Kampagne nicht gefunden ")]),_c('v-card-text',[_vm._v(" Die Kampagne mit der angegebenen ID konnte nicht gefunden werden. Du kannst diese aber erstellen. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.dismissErrorDialog}},[_vm._v("OK")])],1)]:_vm._e(),(_vm.error == 'unknownDoc')?[_c('v-card-title',{staticStyle:{"word-break":"break-word"}},[_vm._v(" Unbekannter Fehler beim Aufruf der Kampagne ")]),_c('v-card-text',[_vm._v(" Als wir versucht haben die Kampagne mit der angegebenen ID aufzurufen, ist ein unerwarteter Feher aufgetreten. Bitte versuche es später erneut."),_c('br'),_vm._v(" Fehlercode: "+_vm._s(_vm.$route.query.code)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.dismissErrorDialog}},[_vm._v("OK")])],1)]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }