<template>
  <div class="campaigns-page admin-page">
    <div class="container">
      <AdminHeader />

      <div class="heading">
        <v-breadcrumbs
          class="breadcrumbs"
          :items="[
            {
              text: 'Kampagnen',
              to: '/admin/campaigns',
            },
          ]"
        >
          <template v-slot:divider>
            <v-icon>chevron_right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :to="item.to" exact exact-active-class="active">
              <h2 class="breadcrumbItem" v-if="item.to == $route.path">
                {{ item.text }}
              </h2>
              <p class="breadcrumbItem" v-else>{{ item.text }}</p>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </div>

      <v-btn class="add" color="primary" elevation="0" to="add">
        <v-icon left> add </v-icon>
        Kampagne hinzufügen
      </v-btn>

      <v-list two-line class="list">
        <v-list-item-group v-if="campaigns && campaigns.campaigns">
          <template v-for="(item, index) in campaigns.campaigns">
            <v-list-item
              class="item"
              :key="item.id"
              :to="'/admin/campaigns/' + item.id"
            >
              <template v-slot:default>
                <v-list-item-content>
                  <v-list-item-title
                    class="itemTitle"
                    v-text="item.name"
                  ></v-list-item-title>

                  <v-list-item-subtitle
                    v-text="
                      item.coupons +
                        ' Gutschein' +
                        (item.coupons != 1 ? 'e' : '')
                    "
                  ></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon>chevron_right</v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>

            <v-divider
              class="mx-3"
              v-if="index < campaigns.length - 1"
              :key="index"
            ></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </div>
    <v-dialog v-model="error" persistent max-width="500">
      <v-card>
        <template v-if="error == 'notFound'">
          <v-card-title style="word-break: break-word;">
            Kampagne nicht gefunden
          </v-card-title>
          <v-card-text>
            Die Kampagne mit der angegebenen ID konnte nicht gefunden werden. Du
            kannst diese aber erstellen.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dismissErrorDialog">OK</v-btn>
          </v-card-actions>
        </template>
        <template v-if="error == 'unknownDoc'">
          <v-card-title style="word-break: break-word;">
            Unbekannter Fehler beim Aufruf der Kampagne
          </v-card-title>
          <v-card-text>
            Als wir versucht haben die Kampagne mit der angegebenen ID
            aufzurufen, ist ein unerwarteter Feher aufgetreten. Bitte versuche
            es später erneut.<br />
            Fehlercode: {{ $route.query.code }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dismissErrorDialog">OK</v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { db } from '@/firebase';
import AdminHeader from '@/components/AdminHeader';

export default {
  name: 'AdminCampaigns',
  components: { AdminHeader },
  firestore: {
    campaigns: db.doc('aggregation/campaigns'),
  },
  data() {
    return {
      campaigns: null,
      error: '',
    };
  },
  watch: {
    '$route.query.error': {
      handler(code) {
        this.error = code;
      },
      immediate: true,
    },
  },
  methods: {
    dismissErrorDialog() {
      this.error = '';
      this.$router.replace({ path: '/admin/campaigns' });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/views/admin/admin.scss';

.campaigns-page {
  .container {
    .add {
      margin-bottom: 16px;
    }

    .list {
      border-radius: 8px;
      border: 1px solid rgba(#000000, 0.1);

      .item {
        .itemTitle {
          font-weight: 500;
        }
      }
    }
  }
}
</style>
